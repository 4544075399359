import { getProperty } from 'dot-prop'

import { getReference, getEntityRefsObject, getRefsObject } from '../mappers'
import { ReferenceMapping } from '../types'

import type { Page } from './types'

export const mapPage = (res: any): Page => {
  const page = {
    id: res.data.id,
    alternates: res.data.alternates || [],
    language: res.data.languageId,
    layout: [],
    metatags: getReference(
      { ...res.data.metaTags },
      getRefsObject(res.data.refs)
    ),
    path: res.data.path,
    theme: res.data.layout.themeId,
    title: res.data.title,
    fields: mapPageFields(res.data.dynamicEntity?.columns || []),
    image: getReference({ ...res.data.image }, getRefsObject(res.data.refs)),
    refs: getRefsObject(res.data.refs),
    views: {},
  }

  page.views = getReference(
    { ...res.data.views },
    getRefsObject(res.data.refs, page)
  )

  return {
    ...page,
    layout: (res.data.layout.schema || []).map((component: any) =>
      getReference({ ...component }, getRefsObject(res.data.layout.refs, page))
    ),
  }
}

export const mapPageFields = (columns: Array<any>): Record<string, any> => {
  const fields: Record<string, any> = {}

  for (const column of columns) {
    if (Array.isArray(column.data)) {
      fields[column.name] = column.data.reduce((prev: any, next: any) => {
        return [...prev, next.value]
      }, [])
    } else if (typeof column.data.value === 'object') {
      if (Array.isArray(column.data.value)) {
        fields[column.name] = column.data.map((item: any) =>
          getReference(
            item,
            getEntityRefsObject(column.type, column.data?.refs)
          )
        )
      } else {
        fields[column.name] = getReference(
          { ...column.data.value },
          getEntityRefsObject(column.type, column.data?.refs)
        )
      }
    } else {
      fields[column.name] = column.data.value
    }
  }

  return fields
}

export const mapPageFieldsByMappings = (
  field: Array<any>,
  mappings: ReferenceMapping[]
) => {
  const data = []

  for (const item of field) {
    const value: Record<string, any> = {}

    for (const mapping of mappings) {
      const property = getProperty({ value: item }, mapping.path)

      if (property) {
        value[mapping.name] = property
      }
    }

    data.push(value)
  }

  return data
}

export const mapPagePath = (res: any): string => {
  return res.data
}
